import * as React from 'react';
import { filter, concat, set, includes, pickBy, without } from 'lodash';
import { createApplication, WorkstreamTrafficControl, entityConfigs, appSectionConfigs as coreAppSectionConfigs, Icons, DataTableNameValue, DataTable, Content, IconTitle, graphql, entityConnectionTypeSetsDefault, FormFieldSet } from '@shapeable/core';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import { themes } from '@shapeable/theme';

const { 
  Page, Post, Position, Slice, Block, MenuItem, Person, Link, 
  Event, Session, SessionGroup, Venue, Room, Profile, Location, Team,
  Chapter, Challenge, ChapterRole, ExpertiseLevel, ChallengeExpertise, 
  TopicExpertise, Lesson, LessonRole, Program,
} = entityConfigs;

import { Reflection, ReflectionType } from './config';

const theme = themes.VILLARS_THEME;
const appSectionConfigs = coreAppSectionConfigs;

const FS_FR = { name: 'fr', label: 'Français' };
const FS_DE = { name: 'de', label: 'Deutsch' };
const LANG_FIELD_SETS = [FS_FR, FS_DE];

Page.extraFieldSets = LANG_FIELD_SETS;
Post.extraFieldSets = LANG_FIELD_SETS;
Position.extraFieldSets = LANG_FIELD_SETS;
Slice.extraFieldSets = LANG_FIELD_SETS;
Block.extraFieldSets = LANG_FIELD_SETS;
MenuItem.extraFieldSets = LANG_FIELD_SETS;
Person.extraFieldSets = LANG_FIELD_SETS;
Link.extraFieldSets = LANG_FIELD_SETS;
Event.extraFieldSets = LANG_FIELD_SETS;
Session.extraFieldSets = LANG_FIELD_SETS;
SessionGroup.extraFieldSets = LANG_FIELD_SETS;
Venue.extraFieldSets = LANG_FIELD_SETS;
Room.extraFieldSets = LANG_FIELD_SETS;

Lesson.slug = 'module';
Lesson.pluralSlug = 'modules';
Lesson.label = 'Module';
Lesson.pluralLabel = 'Modules';

if (LessonRole) {
  LessonRole.slug = 'module-role';
  LessonRole.pluralSlug = 'module-roles';
  LessonRole.label = 'Module Role';
  LessonRole.pluralLabel = 'Module Roles';
}

if (Program) {
  Program.slug = 'program';
  Program.pluralSlug = 'programs';
  Program.label = 'Learning Program';
  Program.pluralLabel = 'Learning Programs';
}


if (appSectionConfigs.conversationChannels) {
  appSectionConfigs.conversationChannels.label = `Institute's Channels`;
}

if (appSectionConfigs.platform) {
  appSectionConfigs.platform.children = [
    ...filter(appSectionConfigs.platform.children, (child: any) => !includes(["platform/courses-lessons"], child.id)),
     {
      id: 'platform/courses-lessons',
      label: "Courses & Modules",
      type: 'list-group',
      entities: ['Course', 'Lesson'],
    },
  ];
}

if (appSectionConfigs.participate) {
  appSectionConfigs.participate.children = [
    ...filter(appSectionConfigs.participate.children, (child: any) => !includes(["reflections"], child.id)),
    {
      id: 'reflections',
      type: 'list',
      entityTypeName: 'Reflection',
      label: "Perspectives",
    },
  ];
}

if (Person) {
  delete Person.form.fields.followingEconomies;
  delete Person.form.fields.followingLocations;
}

if (Profile) {
  delete Profile.form.fields.followingEconomies;
  delete Profile.form.fields.followingLocations;
}


if (Location) {
  Location.includeInMetrics = false;
}

if (Team) {
  Team.includeInMetrics = false;
}

if (Post) {
  Post.includeInMetrics = false;
}

if (Page) {
  Page.includeInMetrics = false;
}

if (Event) {
  Event.includeInMetrics = false;

  Event.extraFields = {
    usefulInformation: { type: 'longText' },
    safeguardingRules: { type: 'longText' },

    name_fr: { type: 'text', label: 'Name', fieldSet: 'fr', badge: "Français" },
    description_fr: { type: 'longText', label: 'Description', fieldSet: 'fr', badge: "Français" },
    usefulInformation_fr: { type: 'longText', label: 'Useful Information', fieldSet: 'fr', badge: "Français" },
    safeguardingRules_fr: { type: 'longText', label: 'Safeguarding Rules', fieldSet: 'fr', badge: "Français" },
 
    name_de: { type: 'text', label: 'Name', fieldSet: 'de', badge: "Deutsch" },
    description_de: { type: 'longText', label: 'Description', fieldSet: 'de', badge: "Deutsch" },
    usefulInformation_de: { type: 'longText', label: 'Useful Information', fieldSet: 'de', badge: "Deutsch" },
    safeguardingRules_de: { type: 'longText', label: 'Safeguarding Rules', fieldSet: 'de', badge: "Deutsch" },
  };
} 

// TODO, make langs more directly configurable? e.g. Position.form.fields.name.langs = ['en', 'fr', 'de'];
  
if (Position) {
  Position.extraFields = {
    name_fr: { type: 'text', label: 'Name', fieldSet: 'fr', badge: "Français" },
    name_de: { type: 'text', label: 'Name', fieldSet: 'de', badge: "Deutsch" },
  };
}

if (Session) {
  Session.extraFields = {
    name_fr: { type: 'text', label: 'Name', fieldSet: 'fr', badge: "Français" },
    description_fr: { type: 'longText', label: 'Description', fieldSet: 'fr', badge: "Français" },
    name_de: { type: 'text', label: 'Name', fieldSet: 'de', badge: "Deutsch" },
    description_de: { type: 'longText', label: 'Description', fieldSet: 'de', badge: "Deutsch" },
  };
}
  
if (Chapter) {
  Chapter.label = 'Hub';
  Chapter.slug = 'hub';
  Chapter.icon = ({ icons }) => icons.Entities.Chapter;
  Chapter.list.banner.content = 'Create Hubs for community members to discuss ideas.'
}


if (Challenge) {
  Challenge.label = 'Theme';
  Challenge.pluralLabel = 'Themes';
  Challenge.slug = 'theme';
  Challenge.pluralSlug = 'themes';
}

if (Reflection) {
  Reflection.label = 'Perspective';
  Reflection.pluralLabel = 'Perspectives';
  Reflection.slug = 'perspective';
  Reflection.pluralSlug = 'perspectives';
}

if (ReflectionType) {
  ReflectionType.label = 'Perspective Type';
  ReflectionType.pluralLabel = 'Perspective Types';
  ReflectionType.slug = 'perspective-type';
  ReflectionType.pluralSlug = 'perspective-types';
}

if (ChapterRole) {
  ChapterRole.label = 'Hub Role';
  ChapterRole.slug = 'hub-role';
}

if (SessionGroup) {
  SessionGroup.extraFields = {
    name_fr: { type: 'text', label: 'Name', fieldSet: 'fr', badge: "Français" },
    shortName_fr: { type: 'text', label: 'Short Name', fieldSet: 'fr', badge: "Français" },
    description_fr: { type: 'longText', label: 'Description', fieldSet: 'fr', badge: "Français" },

    name_de: { type: 'text', label: 'Name', fieldSet: 'de', badge: "Deutsch" },
    shortName_de: { type: 'text', label: 'Short Name', fieldSet: 'de', badge: "Deutsch" },
    description_de: { type: 'longText', label: 'Description', fieldSet: 'de', badge: "Deutsch" },
  }
}

if (Venue) {
  Venue.extraFields = {
    name_fr: { type: 'text', label: 'Name', fieldSet: 'fr', badge: "Français" },
    shortName_fr: { type: 'text', label: 'Short Name', fieldSet: 'fr', badge: "Français" },
    description_fr: { type: 'longText', label: 'Description', fieldSet: 'fr', badge: "Français" },

    name_de: { type: 'text', label: 'Name', fieldSet: 'de', badge: "Deutsch" },
    shortName_de: { type: 'text', label: 'Short Name', fieldSet: 'de', badge: "Deutsch" },
    description_de: { type: 'longText', label: 'Description', fieldSet: 'de', badge: "Deutsch" },
  }
}

if (Room) {
  Room.extraFields = {
    name_fr: { type: 'text', label: 'Name', fieldSet: 'fr', badge: "Français" },
    shortName_fr: { type: 'text', label: 'Short Name', fieldSet: 'fr', badge: "Français" },
    description_fr: { type: 'longText', label: 'Description', fieldSet: 'fr', badge: "Français" },

    name_de: { type: 'text', label: 'Name', fieldSet: 'de', badge: "Deutsch" },
    shortName_de: { type: 'text', label: 'Short Name', fieldSet: 'de', badge: "Deutsch" },
    description_de: { type: 'longText', label: 'Description', fieldSet: 'de', badge: "Deutsch" },
  }
}


if (ExpertiseLevel) {
  ExpertiseLevel.label = 'Level of Expertise or Interest';
}


if (ChallengeExpertise) {
  ChallengeExpertise.label = "Theme Expertise & Interest";
  ChallengeExpertise.pluralLabel = "Theme Expertise & Interests";
}

if (TopicExpertise) {
  TopicExpertise.label = "Topic Expertise & Interest";
  TopicExpertise.pluralLabel = "Topic Expertise & Interests";
}

if (Page) {
  Page.extraFields = {
    name_fr: { type: 'text', fieldSet: 'fr', label: 'Name', badge: "Français" },
    imageAssets_fr: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'fr', label: 'Image Assets', badge: "Français" },
    intro_fr: { type: 'longText', fieldSet: 'fr', label: 'Intro', badge: "Français" },
    content_fr: { type: 'longText', fieldSet: 'fr', label: 'Content', badge: "Français" },
    outro_fr: { type: 'longText', fieldSet: 'fr', label: 'Outro', badge: "Français" },
    title_fr: { type: 'text', fieldSet: 'fr', label: 'Title', badge: "Français" },
    subtitle_fr: { type: 'text', fieldSet: 'fr', label: 'Subtitle', badge: "Français" },
    pretitle_fr: { type: 'text', fieldSet: 'fr', label: 'Pretitle', badge: "Français" },
    name_de: { type: 'text', fieldSet: 'de', label: 'Name', badge: "Deutsch" },
    imageAssets_de: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'de', label: 'Image Assets', badge: "Deutsch" },
    intro_de: { type: 'longText', fieldSet: 'de', label: 'Intro', badge: "Deutsch" },
    content_de: { type: 'longText', fieldSet: 'de', label: 'Content', badge: "Deutsch" },
    outro_de: { type: 'longText', fieldSet: 'de', label: 'Outro', badge: "Deutsch" },
    title_de: { type: 'text', fieldSet: 'de', label: 'Title', badge: "Deutsch" },
    subtitle_de: { type: 'text', fieldSet: 'de', label: 'Subtitle', badge: "Deutsch" },
    pretitle_de: { type: 'text', fieldSet: 'de', label: 'Pretitle', badge: "Deutsch" },
    featurePosts: { type: 'linkedMultiple', label: 'Feature Posts', entityTypeName: "Post", fieldSet: "content" },
  };
}


if (Post) {
  Post.extraFields = {
    name_fr: { type: 'text', fieldSet: 'fr', label: 'Name', badge: "Français" },
    imageAssets_fr: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'fr', label: 'Image Assets', badge: "Français" },
    intro_fr: { type: 'longText', fieldSet: 'fr', label: 'Intro', badge: "Français" },
    content_fr: { type: 'longText', fieldSet: 'fr', label: 'Content', badge: "Français" },
    outro_fr: { type: 'longText', fieldSet: 'fr', label: 'Outro', badge: "Français" },
    title_fr: { type: 'text', fieldSet: 'fr', label: 'Title', badge: "Français" },
    subtitle_fr: { type: 'text', fieldSet: 'fr', label: 'Subtitle', badge: "Français" },
    pretitle_fr: { type: 'text', fieldSet: 'fr', label: 'Pretitle', badge: "Français" },
    name_de: { type: 'text', fieldSet: 'de', label: 'Name', badge: "Deutsch" },
    imageAssets_de: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'de', label: 'Image Assets', badge: "Deutsch" },
    intro_de: { type: 'longText', fieldSet: 'de', label: 'Intro', badge: "Deutsch" },
    content_de: { type: 'longText', fieldSet: 'de', label: 'Content', badge: "Deutsch" },
    outro_de: { type: 'longText', fieldSet: 'de', label: 'Outro', badge: "Deutsch" },
    title_de: { type: 'text', fieldSet: 'de', label: 'Title', badge: "Deutsch" },
    subtitle_de: { type: 'text', fieldSet: 'de', label: 'Subtitle', badge: "Deutsch" },
    pretitle_de: { type: 'text', fieldSet: 'de', label: 'Pretitle', badge: "Deutsch" },
    
    files: { type: 'linkedMultiple', label: 'Files', entityTypeName: "FileAsset", fieldSet: "library" },
  }
}

if (Slice) {
  Slice.extraFields = {
    title_fr: { type: 'text', fieldSet: 'fr', label: 'Title', badge: "Français" },
    images_fr: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'fr', label: 'Image', badge: "Français" },
    intro_fr: { type: 'longText', fieldSet: 'fr', label: 'Intro', badge: "Français" },
    content_fr: { type: 'longText', fieldSet: 'fr', label: 'Content', badge: "Français" },
    outro_fr: { type: 'longText', fieldSet: 'fr', label: 'Outro', badge: "Français" },
    subtitle_fr: { type: 'text', fieldSet: 'fr', label: 'Subtitle', badge: "Français" },
    pretitle_fr: { type: 'text', fieldSet: 'fr', label: 'Pretitle', badge: "Français" },

    title_de: { type: 'text', fieldSet: 'de', label: 'Title', badge: "Deutsch" },
    images_de: { type: 'linkedMultiple', entityTypeName: "ImageAsset", fieldSet: 'de', label: 'Image', badge: "Deutsch" },
    intro_de: { type: 'longText', fieldSet: 'de', label: 'Intro', badge: "Deutsch" },
    content_de: { type: 'longText', fieldSet: 'de', label: 'Content', badge: "Deutsch" },
    outro_de: { type: 'longText', fieldSet: 'de', label: 'Outro', badge: "Deutsch" },
    subtitle_de: { type: 'text', fieldSet: 'de', label: 'Subtitle', badge: "Deutsch" },
    pretitle_de: { type: 'text', fieldSet: 'de', label: 'Pretitle', badge: "Deutsch" },
  }
}


if (Block) {
  Block.extraFields = {
    title_fr: { type: 'text', fieldSet: 'fr', label: 'Title', badge: "Français" },
    image_fr: { type: 'linked', entityTypeName: "ImageAsset", fieldSet: 'fr', label: 'Image', badge: "Français" },
    subtitle_fr: { type: 'text', fieldSet: 'fr', label: 'Subtitle', badge: "Français" },
    content_fr: { type: 'longText', fieldSet: 'fr', label: 'Content', badge: "Français" },

    title_de: { type: 'text', fieldSet: 'de', label: 'Title', badge: "Deutsch" },
    image_de: { type: 'linked', entityTypeName: "ImageAsset", fieldSet: 'de', label: 'Image', badge: "Deutsch" },
    subtitle_de: { type: 'text', fieldSet: 'de', label: 'Subtitle', badge: "Deutsch" },
    content_de: { type: 'longText', fieldSet: 'de', label: 'Content', badge: "Deutsch" },
  }
}


if (Person) {
  Person.extraFields = {
    bio_fr: { type: 'longText', fieldSet: 'fr', label: 'Bio', badge: "Français" },
    bio_de: { type: 'longText', fieldSet: 'de', label: 'Bio', badge: "Deutsch" },
  };
}

if (MenuItem) {
  MenuItem.extraFields = {
    name_fr: { type: 'text', fieldSet: 'fr', label: 'Name', badge: "Français" },
    name_de: { type: 'text', fieldSet: 'de', label: 'Name', badge: "Deutsch" },
  };
}

if (Link) {
  Link.extraFields = {
    label_fr: { type: 'text', fieldSet: 'fr', label: 'Label', badge: "Français" },
    label_de: { type: 'text', fieldSet: 'de', label: 'Label', badge: "Deutsch" },

    url_fr: { type: 'url', fieldSet: 'fr', label: 'URL', badge: "Français" },
    url_de: { type: 'url', fieldSet: 'de', label: 'URL', badge: "Deutsch" },
  };
}


export const application = createApplication({
  theme,
  entityConfigs: {
    // ...entityConfigs,
    ...pickBy(entityConfigs, config => !includes([
      "ImpactStory", "Benefit", "BenefitType", "Cluster", "InfluencingFactor",
      "OfferingType", "MaturityStage", "Product",
      "StrategicPriority", "ProjectType", "ProviderType",  
    ], config.name)),
    Reflection,
    ReflectionType,
  },
  appSectionConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'oceans',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Ecosystems,
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});


const entities = application.entities;

delete entities.KeyIssue;
delete entities.SubTopic;
delete entities.Trend;
delete entities.Sdg;

delete entities.KeyIssueExpertise;
delete entities.SubTopicExpertise;
delete entities.TrendExpertise;
delete entities.SdgExpertise;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;
